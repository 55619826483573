import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {FC, useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {ListLoading} from '../../../../common/table/components/loading/ListLoading'
import {EpisodeDTO, EpisodeStateEnum} from '../../../../models/dbModels'
import {useListView} from '../../core/ListViewProvider'
import {CreateOrUpdateEpisode, GetEpisodeByCondition} from '../../core/_request'
import {InventoryObjectPageWrapper} from '../../../inventoryObject'
import {Preconditions} from '@/app/modules/preconditions'
import {Events} from '@/app/modules/events'

const EditEpisodePage: FC = () => {
  const {seriesId, episodeId} = useParams<{seriesId: string; episodeId: string}>()
  const [_success, setSuccess] = useState<boolean>(false)

  const navigate = useNavigate()
  const {setItemForUpdate} = useListView()
  const [data, setData] = useState<EpisodeDTO | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedTab, setSelectedTab] = useState<string>('items')

  const numericEpisodeId = Number(episodeId)
  const numericSeriesId = Number(seriesId)

  const formik = useFormik<EpisodeDTO>({
    initialValues: {
      episodeId: numericEpisodeId || 0,
      episodeName: '',
      state: EpisodeStateEnum.NotStarted,
      seriesId: numericSeriesId || 0,
      eventId: 0,
      extraEventId: 0,
      isQr: false,
      episodeRewards: [],
      preconditions: [],
    },
    validationSchema: Yup.object({
      episodeName: Yup.string().min(3, 'Minimum 3 characters').required('Required'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)

      try {
        await CreateOrUpdateEpisode(values)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel()
          resetForm()
        }, 2500)
      } catch (error) {
        // Handle error
        setStatus('Error updating episode: ' + error)
      }
    },
  })

  const cancel = () => {
    setItemForUpdate?.(undefined)
    navigate('/series/edit/' + seriesId)
  }

  useEffect(() => {
    const fetchEpisode = async () => {
      if (!isNaN(numericEpisodeId) && numericEpisodeId !== 0) {
        try {
          const response = await GetEpisodeByCondition({episodeId: numericEpisodeId})
          if (response && response.data && response.data[0]) {
            const episode = response.data[0]
            formik.setValues({
              episodeId: episode.episodeId,
              episodeName: episode.episodeName,
              state: episode.state || EpisodeStateEnum.NotStarted,
              seriesId: numericSeriesId,
              eventId: episode.eventId || 0,
              extraEventId: episode.extraEventId || 0,
              isQr: episode.isQr || false,
              episodeRewards: episode.episodeRewards || [],
              preconditions: episode.preconditions || [],
            })
            setItemForUpdate?.(episode)
            setData(episode)
          }
        } catch (error) {
          console.error('Error fetching episode:', error)
        } finally {
          setIsLoading(false)
        }
      } else {
        console.error('Invalid episodeId:', episodeId)
        setIsLoading(false)
      }
    }

    fetchEpisode()
  }, [numericEpisodeId, numericSeriesId, setItemForUpdate])

  return (
    <KTCard>
      <KTCardBody>
        <h1>Epizód szerkesztés</h1>
        <div className='form-container'>
          <div className='d-flex justify-content-between align-items-center mb-5'>
            <h2>{data?.episodeName ?? 'Epizód szerkesztése'}</h2>
            <div>
              <button type='button' onClick={cancel} className='btn btn-light me-3'>
                Vissza
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => formik.handleSubmit()}
                disabled={isLoading || formik.isSubmitting}
              >
                Szerkesztés
              </button>
            </div>
          </div>

          <form id='kt_modal_add_edit_episode_form' onSubmit={formik.handleSubmit}>
            <div className='mb-10'>
              <label className='form-label required'>Epizód neve</label>
              <input
                type='text'
                name='episodeName'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.episodeName}
                className={clsx('form-control', {
                  'is-invalid': formik.touched.episodeName && formik.errors.episodeName,
                  'is-valid': formik.touched.episodeName && !formik.errors.episodeName,
                })}
              />
              {formik.touched.episodeName && formik.errors.episodeName && (
                <div className='invalid-feedback'>{formik.errors.episodeName}</div>
              )}
            </div>

            <div className='form-check form-switch form-check-custom form-check-solid mb-8'>
              <input
                type='checkbox'
                autoComplete='off'
                checked={formik.values.isQr}
                className='form-check-input py-5 custom-switch'
                onChange={() => {
                  formik.setFieldValue('isQr', !formik.values.isQr)
                }}
              />
              <label className='form-check-label' htmlFor='flexSwitchDefault'>
                QR kóddal indítható
              </label>
            </div>
            {/* Add more fields as necessary */}
          </form>

          <ul className='nav nav-tabs mb-5'>
            <li className='nav-item'>
              <a
                className={clsx('nav-link', {active: selectedTab === 'items'})}
                data-bs-toggle='tab'
                href='#items'
                onClick={() => setSelectedTab('items')}
              >
                Reward-ok
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx('nav-link', {active: selectedTab === 'preconditions'})}
                data-bs-toggle='tab'
                href='#preconditions'
                onClick={() => setSelectedTab('preconditions')}
              >
                Előfeltételek
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx('nav-link', {active: selectedTab === 'event'})}
                data-bs-toggle='tab'
                href='#event'
                onClick={() => setSelectedTab('event')}
              >
                Esemény
              </a>
            </li>
            {/* <li className='nav-item'>
              <a
                className={clsx('nav-link', {active: selectedTab === 'extra-event'})}
                data-bs-toggle='tab'
                href='#extra-event'
                onClick={() => setSelectedTab('extra-event')}
              >
                Extra Event
              </a>
            </li> */}
          </ul>

          <div className='tab-content'>
            <div
              id='items'
              className={clsx('tab-pane fade', {'show active': selectedTab === 'items'})}
            >
              {/* Item list content */}
              {selectedTab === 'items' && (
                <InventoryObjectPageWrapper episodeId={formik.values.episodeId} />
              )}
            </div>
            <div
              id='preconditions'
              className={clsx('tab-pane fade', {'show active': selectedTab === 'preconditions'})}
            >
              {/* Preconditions content */}
              <Preconditions episodeId={formik.values.episodeId} />
            </div>
            <div
              id='event'
              className={clsx('tab-pane fade', {'show active': selectedTab === 'event'})}
            >
              {/* Event content */}
              <Events episodeId={formik.values.episodeId} />
            </div>
            <div
              id='extra-event'
              className={clsx('tab-pane fade', {'show active': selectedTab === 'extra-event'})}
            >
              {/* Extra Event content */}
            </div>
          </div>

          {(formik.isSubmitting || isLoading) && <ListLoading />}
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default EditEpisodePage
