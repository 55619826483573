import { useQuery } from 'react-query';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';
import { GetInventoryObjectByCondition } from '../core/_request';
import { GetInventoryObjectByConditionRequest } from '../../../models/dbModels';
import { EditOrCreateInventoryObjectModalForm } from './content/InventoryObjectModalForm';
import { FC } from 'react';

type Props = {
  episodeId: number;
};

const InventoryObjectEditOrCreateModalFormWrapper: FC<Props> = ({episodeId}) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery: boolean = isNotEmpty(itemForUpdate) && isNotEmpty(itemForUpdate?.inventoryObjectId);

  const {
    isLoading,
    data: inventoryObject,
    error,
  } = useQuery(
    `inventoryObject-${itemForUpdate?.inventoryObjectId}`,
    () => {
      if (itemForUpdate?.inventoryObjectId) {
        const request: GetInventoryObjectByConditionRequest = {
          inventoryObjectId: itemForUpdate.inventoryObjectId
      }
        return GetInventoryObjectByCondition(request);
      }
      return undefined;
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForUpdate?.(undefined);
        console.error('Query Error:', err);
      },
    }
  );

  if (error) {
    console.error('Error loading inventoryObject data:', error);
  }

  return <EditOrCreateInventoryObjectModalForm isLoading={isLoading} data={inventoryObject?.data[0]}/>;
};

export { InventoryObjectEditOrCreateModalFormWrapper };
