import {Field} from '@/app/components/CustomInput'
import {DropdownMenu} from '@/app/components/DropdownMenu'
import {Modal} from '@/app/components/Modal'
import {dropdownMenuConfig} from '@/app/components/config'
import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {createOrUpdatePoi, deletePoiById, updatePOIImage} from './api'

const requiredMessage = 'A mező kitöltése kötelező'

const schema = Yup.object().shape({
  poiName: Yup.string().required(requiredMessage),
  coordinates: Yup.array().of(
    Yup.object().shape({
      latitude: Yup.number().required(requiredMessage),
      longitude: Yup.number().required(requiredMessage),
    })
  ),
  radius: Yup.number().required(requiredMessage),
  poiIcon: Yup.string().required(requiredMessage),
  imageSource: Yup.string().required(requiredMessage),
  description: Yup.string()
    .required(requiredMessage)
    .min(3, 'Minimum 3 karakter.')
    .max(1000, 'Max. 1000 karakter'),
})

export const POI = ({episodeId, eventId, poi, setModal, modal, refetch}: any) => {
  const isCreate = modal === 'createPOI'
  const isUpdate = modal === 'updatePOI'
  const isDelete = modal === 'deletePOI'
  const formNeeded = isCreate || isUpdate

  const onFormSubmit = async (values: any) => {
    const data = {
      poi: {
        poiId: poi?.poiId || null,
        eventId: poi?.eventId || eventId || '',
        poiName: values.poiName,
        coordinateId: poi?.coordinate?.coordinateId || 0,
        coordinates: values.coordinates,
        radius: values.radius,
        poiIcon: values.poiIcon,
        imageSource: values.imageSource,
        description: values.description,
        companionMode: poi?.companionMode || false,
        companionDelay: poi?.companionDelay || 0,
      },
    }

    const res: any = await createOrUpdatePoi(data)

    if (res?.message) {
      alert(res.message)
      return
    }
    if (!res?.poiId) {
      alert('Hiányzó poi id')
      return
    }

    // Create a new FormData object
    const formData = new FormData()
    // Append the file to the FormData object
    const poiIconFileInput = document.getElementById('poiIconFile') as HTMLInputElement
    const poiIconFile: any = (poiIconFileInput?.files || [])[0]
    if (poiIconFile) {
      formData.append('icon', poiIconFile)
    }
    // Append the file to the FormData object
    const imageSourceFileInput = document.getElementById('imageSourceFile') as HTMLInputElement
    const imageSourceFile: any = (imageSourceFileInput?.files || [])[0]
    if (imageSourceFile) {
      formData.append('image', imageSourceFile)
    }

    if (poiIconFile || imageSourceFile) {
      const fileUploadRes = await updatePOIImage({form: formData, eventId, poiId: res?.poiId})
      if (fileUploadRes.message) {
        alert(fileUploadRes.message)
        return
      }
    }
    await new Promise((res) => setTimeout(res, 250))
    refetch()
    setModal('')
  }

  const onDeleteClick = async () => {
    const result = await deletePoiById({poiId: poi.poiId})
    if (!result || !result.message) {
      await new Promise((res) => setTimeout(res, 250))
      refetch()
      setModal('')
      return
    }
    alert(result?.message)
  }
  return (
    <>
      {poi ? (
        <div className='col-12 col-md-6  p-10'>
          <div className='d-flex justify-content-between'>
            <h2>POI</h2>
            <DropdownMenu>
              {{
                ...dropdownMenuConfig,
                edit: {
                  ...dropdownMenuConfig.edit,
                  onClick() {
                    setModal('updatePOI')
                  },
                },
                remove: {
                  ...dropdownMenuConfig.remove,
                  onClick() {
                    setModal('deletePOI')
                  },
                },
              }}
            </DropdownMenu>
          </div>
          <div className='d-flex justify-content-between mt-5'>
            <span>Név</span> <b>{poi.poiName}</b>
          </div>
          <div className='d-flex justify-content-between mt-5'>
            <span>Sugár</span> <b>{poi.radius}</b>
          </div>

          <div className='d-flex justify-content-between mt-5'>
            <span>Koordináták</span>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: 8}}>
              {poi.coordinates.map(({latitude, longitude}: any, index: number) => {
                return (
                  <div key={index}>
                    ({latitude}, {longitude})
                  </div>
                )
              })}
            </div>
          </div>

          <div className='d-flex justify-content-between mt-5'>
            <span>POI kép</span> <b>{poi.imageSource}</b>
          </div>

          <div className='d-flex justify-content-between mt-5'>
            <span>Popup kép</span> <b>{poi.poiIcon}</b>
          </div>

          <div className='d-flex justify-content-between mt-5'>
            <span>Popup szöveg</span> <b>{poi.description}</b>
          </div>
        </div>
      ) : null}
      <Modal
        open={isCreate || isDelete || isUpdate}
        setModal={setModal}
        title={isUpdate ? 'POI módosítása' : isDelete ? 'POI törlése' : 'Új POI'}
      >
        {formNeeded && (
          <Formik
            initialValues={{
              poiName: poi?.poiName || '',
              radius: poi?.radius || '',
              coordinates: poi?.coordinates || [{latitude: '', longitude: ''}],
              poiIcon: poi?.poiIcon || '',
              imageSource: poi?.imageSource || '',
              description: poi?.description || '',
            }}
            validationSchema={schema}
            onSubmit={onFormSubmit}
            validator={() => ({})}
          >
            {({values}) => (
              <Form>
                <Field name='poiName' label={'Név'} required />
                <Field name='radius' label={'Sugár'} required />
                <div className='row'>
                  {/* <div className='col-6'>
                  <Field name='latitude' label={'Szélesség'} required />
                </div>
                <div className='col-6'>
                  <Field name='longitude' label={'Hosszúság'} required />
                </div> */}

                  <h3 style={{marginTop: 20}}>Koordináták</h3>
                  <FieldArray name='coordinates'>
                    {({push, remove}) => (
                      <div>
                        {values.coordinates.map((_: any, index: number) => (
                          <div key={index} className='coordinate-group mb-4'>
                            <div className='flex space-x-4 row align-items-start'>
                              <div className='col-5'>
                                <Field
                                  label={'Szélesség'}
                                  name={`coordinates.${index}.latitude`}
                                  placeholder='Szélesség'
                                  type='number'
                                />
                                <div className='fv-plugins-message-container'>
                                  <ErrorMessage
                                    name={`coordinates.${index}.latitude`}
                                    component='div'
                                    className=' fv-help-block'
                                  />
                                </div>
                              </div>
                              <div className='col-5'>
                                <Field
                                  label={'Hosszúság'}
                                  name={`coordinates.${index}.longitude`}
                                  placeholder='Hosszúság'
                                  type='number'
                                />
                                <div className='fv-plugins-message-container'>
                                  <ErrorMessage
                                    name={`coordinates.${index}.longitude`}
                                    component='div'
                                    className='fv-help-block'
                                  />
                                </div>
                              </div>
                              <div
                                style={{display: 'flex', alignSelf: 'end', marginBottom: 4}}
                                className='col-2'
                              >
                                {index > 0 && (
                                  <button
                                    type='button'
                                    onClick={() => remove(index)}
                                    className='btn btn-danger'
                                  >
                                    Törlés
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                          <button
                            type='button'
                            onClick={() => push({latitude: '', longitude: ''})}
                            className='mt-4 btn btn-success'
                          >
                            Új koordináta
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <div className='row'>
                    <div className='col-6'>
                      <Field
                        name='poiIcon'
                        label={'POI ikon kép'}
                        required
                        isFileUpload='poiIconFile'
                      />
                    </div>
                    <div className='col-6'>
                      <Field
                        name='imageSource'
                        label={'Popup kép'}
                        required
                        isFileUpload='imageSourceFile'
                      />
                    </div>
                    <Field name='description' label='Leírás' required />
                  </div>
                </div>

                <div className='d-flex justify-content-center align-items-center mt-10'>
                  <button type='submit' className='btn btn-primary'>
                    {isCreate ? 'HOZZÁADÁS' : 'MÓDOSÍTÁS'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {isDelete && (
          <>
            <h3 className='text-center'>Biztosan törlöd?</h3>
            <div className='d-flex justify-content-center align-items-center mt-10'>
              <button onClick={() => setModal('')} className='btn btn-light me-4'>
                NEM
              </button>
              <button onClick={onDeleteClick} className='btn btn-danger'>
                IGEN
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
