import {apiURL} from '@/app/consts'
import axios from 'axios'
import {useQuery} from 'react-query'

const baseURL = apiURL + 'Event'

interface GetEventsByConditionParams {
  episodeId: number
  needArGame: boolean
  needPoi: boolean
  needSurvey: boolean
}

export const getEventsByCondition = async (params: GetEventsByConditionParams) => {
  let result
  try {
    const response = await axios.get(`${baseURL}/GetEventsByCondition`, {
      params,
    })
    result = response.data
  } catch (error) {
    console.error(error)
  }
  return result
}

export const createOrUpdateEvent = async (params: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/CreateOrUpdateEvent`, params)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export const updateEventImage = async ({form, eventId}: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/UpdateEventImage?eventId=${eventId}`, form, {
      headers: {'Content-Type': 'multipart/form-data'},
    })
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export const useEventsByEpisodeId = (episodeId: number) => {
  const {data: response, refetch} = useQuery({
    queryKey: ['events', episodeId],
    queryFn: async () =>
      await getEventsByCondition({episodeId, needPoi: true, needArGame: true, needSurvey: true}),
  })
  const data = response?.data || []
  const first = data ? data[0] || null : null

  const event = first
    ? {
        id: first.eventId,
        name: first.eventName,
        description: first.description,
        imageSource: first.imageSource,
      }
    : null

  const ar =
    first && first.arGame
      ? {
          ...first.arGame,
        }
      : null

  const poi =
    first && first.poi
      ? {
          ...first.poi,
        }
      : null

  let questions = first ? first.survey : null
  if (!questions?.length) {
    questions = null
  }

  return {event, ar, poi, questions, refetch}
}
