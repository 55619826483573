import { useQuery } from 'react-query';
import { isNotEmpty } from '../../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';
import { getUserById } from '../core/_requests';
import { UserEditModalForm } from './content/UserEditModalForm';

const UserEditOrCreateModalFormWrapper = () => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery: boolean = isNotEmpty(itemForUpdate) && isNotEmpty(itemForUpdate?.userId);

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `user-${itemForUpdate?.userId}`,
    () => {
      if (itemForUpdate?.userId) {
        return getUserById(itemForUpdate.userId);
      }
      return undefined;
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForUpdate(undefined);
        console.error('Query Error:', err);
      },
    }
  );

  if (error) {
    console.error('Error loading user data:', error);
  }

  return <UserEditModalForm isUserLoading={isLoading} User={user} />;
};

export { UserEditOrCreateModalFormWrapper };
