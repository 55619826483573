import axios, {AxiosResponse, AxiosError} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {
  CreateOrUpdateEpisodeRequest,
  EpisodeDTO,
  EpisodeStateEnum,
  GetEpisodeByConditionRequest,
  UserCompletedEpisodeDTO,
  transformRequestData,
} from '../../../models/dbModels'
import {
  GetEpisodeByConditionResponse,
  GetUserCompletedEpisodeResponse,
} from '../../../models/episode'

const API_EPISODES_URL = process.env.REACT_APP_ALTERGO_API + 'Episode/'

const appendField = (formData: FormData, key: string, value: any) => {
  if (value !== undefined && value !== null) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, item.toString())
      })
    } else {
      formData.append(key, value.toString())
    }
  }
}

const GetEpisodeByCondition = (
  request: GetEpisodeByConditionRequest
): Promise<GetEpisodeByConditionResponse> => {
  const requestQuery = new URLSearchParams({
    userId: request.userId?.toString() || '',
    episodeId: request.episodeId?.toString() || '',
    seriesId: request.seriesId?.toString() || '',
    eventId: request.eventId?.toString() || '',
    extraEventId: request.extraEventId?.toString() || '',
    isQr: request.isQr?.toString() || '',
    needEpisodeRewards: request.needEpisodeRewards?.toString() || 'false',
    needPreconditions: request.needPreconditions?.toString() || 'false',
    page: request.page?.toString() || '',
    'per-page': request.perPage?.toString() || '',
    sort: request.sort || '',
    order: request.order || '',
    search: request.search || '',
  }).toString()

  return axios
    .get<GetEpisodeByConditionResponse>(`${API_EPISODES_URL}GetEpisodeByCondition?${requestQuery}`)
    .then((response: AxiosResponse<GetEpisodeByConditionResponse>) => response.data)
}

const GetUserCompletedEpisodeByCondition = (
  query: string
): Promise<GetUserCompletedEpisodeResponse> => {
  return axios
    .get(`${API_EPISODES_URL}GetUserCompletedEpisodeByCondition?${query}`)
    .then((d: AxiosResponse<GetUserCompletedEpisodeResponse>) => d.data)
}

const CreateOrUpdateEpisode = async (episode: EpisodeDTO): Promise<AxiosResponse<EpisodeDTO>> => {
  const url = `${API_EPISODES_URL}CreateOrUpdateEpisode`

  try {
    const _request: CreateOrUpdateEpisodeRequest = transformRequestData({
      episodeId: episode.episodeId,
      episodeName: episode.episodeName,
      state: episode.state || EpisodeStateEnum.NotStarted,
      isQr: episode.isQr,
      seriesId: episode.seriesId,
      eventId: episode.eventId,
      extraEventId: episode.extraEventId,
      episodeRewards: episode.episodeRewards,
      preconditions: episode.preconditions,
    })

    const response = await axios.post<EpisodeDTO>(url, _request, {
      headers: {'Content-Type': 'application/json'},
    })
    return response
  } catch (error) {
    const err = error as AxiosError
    if (err.response) {
      console.error('Server response error:', err.response.data)
    } else {
      console.error('Error saving episode:', err.message)
    }
    throw error
  }
}

const DeleteEpisodeRequest = (episodeId: ID): Promise<void> => {
  return axios.post(`${API_EPISODES_URL}DeleteEpisode?episodeId=${episodeId}`, {})
}

const DuplicateEpisodeRequest = (episodeId: ID): Promise<void> => {
  return axios.post(`${API_EPISODES_URL}Duplicate`, {
    episodeId,
  })
}

const ReorderEpisodeRequest = (episodeId: ID, moveUp: boolean): Promise<void> => {
  return axios.post(`${API_EPISODES_URL}ChangeEpisodeOrder`, {
    episodeId,
    moveUp,
  })
}

export {
  GetEpisodeByCondition,
  GetUserCompletedEpisodeByCondition,
  CreateOrUpdateEpisode,
  DeleteEpisodeRequest,
  ReorderEpisodeRequest,
  DuplicateEpisodeRequest,
}
