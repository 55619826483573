/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo, createContext } from 'react';
import { useQuery } from 'react-query';
import {
  initialQueryState,
  PaginationState,
  QUERIES,
  QueryResponseContextProps,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers';
import { useQueryRequest } from './QueryRequestProvider';
import { GetSeriesByCondition } from './_requests';
import { SeriesDTO } from '../../../models/dbModels';

const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '', foldersListRefetch: () => { }, foldersListResponse: [], SeriesListRefetch: () => { }, SeriesListResponse: [] };

const QueryResponseContext = createContext<QueryResponseContextProps<SeriesDTO>>(initialQueryResponse);

  const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
    const { state } = useQueryRequest();
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  
    useEffect(() => {
      if (query !== updatedQuery) {
        setQuery(updatedQuery);
      }
    }, [updatedQuery]);
  
    const { isFetching, refetch, data: response } = useQuery(
      `${QUERIES.SERIES}-${query}`,
      async () => {
        const series = await GetSeriesByCondition({
          page: state.page,
          perPage: state['per-page'] || 10,
          sort: state.sort,
          order: state.order,
          search: state.search,
        });
        console.log("series", series);
        return { data: series };
      },
      { keepPreviousData: true, refetchOnWindowFocus: false }
    );
  
    return (
      <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
        {children}
      </QueryResponseContext.Provider>
    );
  };

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  console.log('Context response:', response);
  if (!response || !response.data) {
    return [];
  }
  return response.data;
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
