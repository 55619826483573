import {FC} from 'react'
import {Column} from 'react-table'
import {KTCard} from '../../../../_metronic/helpers'
import {CustomHeader} from '../../../common/table/columns/CustomHeader'
import {ListHeader} from '../../../common/table/components/header/ListHeader'
import {EpisodeDTO} from '../../../models/dbModels'
import {EpisodeListToolbar} from '../components/header/EpisodeListToolbar'
import {useListView} from '../core/ListViewProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseData,
} from '../core/QueryResponseProvider'
import {ActionsCell} from './columns/ActionsCell'
import {CustomCheckboxCell} from './columns/CustomCheckboxCell'
import {Table} from '../../../common/table/Table'
import {EditOrCreateEpisodeModal} from '../edit-or-create-modal/EditOrCreateEpisodeModal'
import {ReorderCell} from './columns/ReorderCell'

type Props = {
  seriesId: number
}

const EpisodeList: FC<Props> = ({seriesId}) => {
  const {itemForUpdate, itemForView} = useListView()
  const {updateState, state} = useQueryRequest()
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const episodes = useQueryResponseData() // Fetch episodes data

  const columns: ReadonlyArray<Column<EpisodeDTO>> = [
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='SORREND'
          className='text-start min-w-50px w-50px'
        />
      ),
      id: 'reorder',
      disableSortBy: true,
      Cell: ({...props}) => (
        <ReorderCell
          data={props.data[props.row.index]}
          index={props.row.index}
          lastIndex={props.data.length - 1}
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='Név'
          className='min-w-125px'
        />
      ),
      accessor: 'episodeName',
      disableSortBy: true,
      Cell: ({...props}) => props.data[props.row.index].episodeName,
    },
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='ID'
          className='min-w-125px'
        />
      ),
      accessor: 'episodeId',
      disableSortBy: true,
      Cell: ({...props}) => props.data[props.row.index].episodeId,
    },
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='Állapot'
          className='min-w-125px'
        />
      ),
      accessor: 'state',
      disableSortBy: true,
      Cell: ({...props}) => props.data[props.row.index].state,
    },
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='QR'
          className='min-w-125px'
        />
      ),
      accessor: 'isQr',
      disableSortBy: true,
      Cell: ({...props}) => <CustomCheckboxCell episode={props.data[props.row.index]} />,
    },
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='TEVÉKENYSÉG'
          className='text-end min-w-100px'
        />
      ),
      id: 'actions',
      disableSortBy: true,
      Cell: ({...props}) => <ActionsCell data={props.data[props.row.index]} />,
    },
  ]

  console.log(episodes)

  return (
    <>
      <h1 className='h1 page-heading'>Epizódok</h1>
      <KTCard>
        <ListHeader
          showSearch={true}
          updateState={updateState}
          toolbarComponent={<EpisodeListToolbar />}
        />
        <Table
          pagination={pagination}
          isLoading={isLoading}
          updateState={updateState}
          loadedData={episodes}
          columns={columns}
        />
      </KTCard>

      {itemForView !== undefined && (
        <EditOrCreateEpisodeModal seriesId={seriesId} isLoading={isLoading} />
      )}
      {itemForUpdate !== undefined && (
        <EditOrCreateEpisodeModal seriesId={seriesId} isLoading={isLoading} />
      )}
    </>
  )
}

export {EpisodeList}
