import {Field} from '@/app/components/CustomInput'
import {DropdownMenu} from '@/app/components/DropdownMenu'
import {Modal} from '@/app/components/Modal'
import {dropdownMenuConfig} from '@/app/components/config'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {createOrUpdateSurvey, deleteQuestionById} from './api'
import {useState} from 'react'

const requiredMessage = 'A mező kitöltése kötelező'

const schema = Yup.object().shape({
  questionName: Yup.string().required(requiredMessage),
  answers: Yup.string().required(requiredMessage),
  correctAnswerIndex: Yup.number().required(requiredMessage),
})

export const Questions = ({episodeId, eventId, questions, setModal, modal, refetch}: any) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0)
  const isCreate = modal === 'createQuestion'
  const isUpdate = modal === 'updateQuestion'
  const isDelete = modal === 'deleteQuestion'
  const formNeeded = isCreate || isUpdate
  // const {mutate} = usePOI()
  const question: any = (questions || []).find(
    ({testQuestionId}: any) => testQuestionId === selectedQuestionId
  )

  const onFormSubmit = async (values: any) => {
    const newOptions = values.answers.split(', ').map((text: string) => ({
      text,
    }))

    const correctOption = newOptions.find((opt: any) => !!opt.isCorrectAnswer)

    const data = {
      survey: {
        testQuestionId: question?.testQuestionId || 0,
        question: values.questionName,
        correctOptionId: parseInt(values.correctAnswerIndex) - 1 || 0,
        correctOption,
        eventId,
        options: newOptions,
      },
    }

    if (!question) {
      delete data.survey.testQuestionId
    }
    if (isCreate) {
      delete data.survey.testQuestionId
    }

    const res: any = await createOrUpdateSurvey(data)
    if (res?.message) {
      // alert(res.message)
      return
    }

    await new Promise((res) => setTimeout(res, 250))
    refetch()
    setModal('')
  }

  const onDeleteConfirm = async () => {
    const result = await deleteQuestionById({testQuestionId: selectedQuestionId})
    if (!result || !result.message) {
      await new Promise((res) => setTimeout(res, 250))
      refetch()
      setModal('')
      return
    }
    alert(result?.message)
  }

  return (
    <>
      {questions ? (
        <div className='col-12  p-8'>
          <div className='d-flex justify-content-between'>
            <h2>Kérdések</h2>
          </div>

          <table className='preconditions-table table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr>
                <td>Kérdés</td>
                <td>Válaszok</td>
                <td>Helyes válasz sorszám</td>
                <td>Törlés</td>
              </tr>
            </thead>
            <tbody>
              {questions.map((question: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{question?.question}</td>
                    <td>{question?.options.map(({text}: any) => text).join(', ')}</td>
                    <td>{parseInt(question?.correctOptionId) + 1}</td>
                    <td>
                      <DropdownMenu>
                        {{
                          ...dropdownMenuConfig,
                          edit: {
                            ...dropdownMenuConfig.edit,
                            onClick() {
                              setSelectedQuestionId(question.testQuestionId)
                              setModal('updateQuestion')
                            },
                          },
                          remove: {
                            ...dropdownMenuConfig.remove,
                            onClick() {
                              setSelectedQuestionId(question.testQuestionId)
                              setModal('deleteQuestion')
                            },
                          },
                        }}
                      </DropdownMenu>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : null}
      <Modal
        open={isCreate || isDelete || isUpdate}
        setModal={setModal}
        title={isUpdate ? 'Kérdés módosítása' : isDelete ? 'Kérdés törlése' : 'Új kérdés'}
      >
        {formNeeded && (
          <Formik
            initialValues={{
              questionName: question?.question || '',
              answers: question?.options?.map(({text}: any) => text).join(', ') || '',
              correctAnswerIndex: question?.correctOptionId + 1 || '',
            }}
            validationSchema={schema}
            onSubmit={onFormSubmit}
          >
            <Form>
              <Field name='questionName' label={'Kérdés neve'} required />
              <Field
                name='answers'
                label={'Válaszok felsorolva'}
                required
                placeholder='Pl. Igen, Nem, Talán'
              />
              <Field name='correctAnswerIndex' label={'Helyes válasz sorszáma'} required />

              <div className='d-flex justify-content-center align-items-center mt-10'>
                <button type='submit' className='btn btn-primary'>
                  {isCreate ? 'HOZZÁADÁS' : 'MÓDOSÍTÁS'}
                </button>
              </div>
            </Form>
          </Formik>
        )}
        {isDelete && (
          <>
            <h3 className='text-center'>Biztosan törlöd?</h3>
            <div className='d-flex justify-content-center align-items-center mt-10'>
              <button onClick={() => setModal('')} className='btn btn-light me-4'>
                NEM
              </button>
              <button onClick={onDeleteConfirm} className='btn btn-danger'>
                IGEN
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
