import {apiURL} from '@/app/consts'
import axios from 'axios'
const baseURL = apiURL + 'Event'

export const updatePOIImage = async ({form, eventId, poiId}: any) => {
  let result
  try {
    const response = await axios.post(
      `${baseURL}/UpdatePoiImages?eventId=${eventId}&poiId=${poiId}`,
      form,
      {
        headers: {'Content-Type': 'multipart/form-data'},
      }
    )
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export const createOrUpdatePoi = async (params: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/CreateOrUpdatePoi`, params)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export const deletePoiById = async (data: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/DeletePoi`, data)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}
