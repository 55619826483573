import { CurrencyInputProps } from "react-currency-input-field";
import { ListView, VatList } from "./models";

const QUERIES = {
  USERS_LIST: 'users-list',
  SERIES: 'series',
  EPISODES: 'episodes',
  INVENTORY_OBJECTS: 'inventory-objects',
  
  GET_USER_BY_ID: 'get-user-by-id',
}

const DATE_FORMATS = {
  DATETIME: "yyyy-MM-dd HH:mm",
  DATE: "yyyy-MM-dd",
  TIME: "HH:mm"
}

const ProductTypes: ListView[] = [
  { id: 0, code: "product", name: "Termék" },
  { id: 1, code: "subscription", name: "Előfizetés" },
  { id: 2, code: "ticket", name: "Belépő" },
  { id: 3, code: "event", name: "Időpont" },
];

const CalendarTypes: ListView[] = [
  { id: 0, code: "group_booking", name: "Csoportos foglalás" },
  { id: 1, code: "personal_booking", name: "Egyéni foglalás" },
  { id: 2, code: "free_time", name: "Nem foglalható" }
];

const VatTypes: VatList[] = [
  { id: 0, vat_amount: "5", vat: "5", name: "5%" },
  { id: 1, vat_amount: "18", vat: "18", name: "18%" },
  { id: 2, vat_amount: "27", vat: "27", name: "27%" },
  { id: 3, vat_amount: "0", vat: "TAM", name: "TAM" },
  { id: 4, vat_amount: "0", vat: "AAM", name: "AAM" },
  { id: 5, vat_amount: "0", vat: "EUT", name: "EUT" },
  { id: 6, vat_amount: "0", vat: "EUKT", name: "EUKT" },
  { id: 7, vat_amount: "0", vat: "F.AFA", name: "F.AFA" },
  { id: 8, vat_amount: "0", vat: "K.AFA", name: "K.AFA" },
  { id: 9, vat_amount: "0", vat: "TAHK", name: "TAHK" },
  { id: 10, vat_amount: "0", vat: "TEHK", name: "TEHK" },
  { id: 11, vat_amount: "0", vat: "EUFADE", name: "EUFADE" },
  { id: 12, vat_amount: "0", vat: "EUE", name: "EUE" },
  { id: 13, vat_amount: "0", vat: "HO", name: "HO" },
];


const RegularityData: ListView[] = [
  { id: 0, code: "day", name: "Napi" },
  { id: 1, code: "week", name: "Hetente" },
  { id: 2, code: "month", name: "Havonta" },
  { id: 3, code: "year", name: "Évente" },
];

const options: ReadonlyArray<CurrencyInputProps['intlConfig']> = [
  {
    locale: 'hu-HU',
    currency: 'HUF',
  },
  {
    locale: 'en-US',
    currency: 'USD',
  },
  {
    locale: 'en-GB',
    currency: 'GBP',
  },
  {
    locale: 'ja-JP',
    currency: 'JPY',
  },
  {
    locale: 'en-IN',
    currency: 'INR',
  },
];

const RoleData: ListView[] = [
  { id: 0, code: "business_manager", name: "Alkalmazott" },
  { id: 1, code: "business_administrator", name: "Vezető" },
];


const GenderData: ListView[] = [
  { id: 0, code: "male", name: "Férfi" },
  { id: 1, code: "female", name: "Nő" },
  { id: 2, code: "unknown", name: "Nem nyilatkozom" },
];

const StateData: ListView[] = [
  { id: 0, code: "waiting", name: "Várakozó" },
  { id: 1, code: "inactive", name: "Passzív" },
  { id: 2, code: "active", name: "Aktív" },
];

const UserTypesData = [
  { id: 0, name: "Végfelhasználó" },
  { id: 1, name: "Üzleti Felhasználó" },
  { id: 2, name: "SBC Adminisztrátor" },
];

export const overrideRmcsStrings = {
  allItemsAreSelected: "Minden elem kiválasztva.",
  clearSearch: "Keresés alaphelyzetbe",
  clearSelected: "Kiválasztottak eltávolítása",
  noOptions: "Nincsenek opciók",
  search: "Keresés",
  selectAll: "Az összes kiválasztása",
  selectAllFiltered: "Összes kiválasztása (Szűrés után)",
  selectSomeItems: "Kiválaszt...",
  create: "Létrehoz",
}


export { QUERIES, DATE_FORMATS, ProductTypes, RegularityData, options, CalendarTypes, VatTypes,RoleData,GenderData,StateData,UserTypesData}
