import axios, {AxiosResponse, AxiosError} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {
  transformRequestData,
  InventoryObjectTypeEnum,
  InventoryObjectDTO,
  CreateOrUpdateInventoryObjectRequest,
  GetInventoryObjectByConditionRequest,
} from '../../../models/dbModels'

const API_INVENTORY_OBJECTS_URL = process.env.REACT_APP_ALTERGO_API + 'InventoryObject/'

const appendField = (formData: FormData, key: string, value: any) => {
  if (value !== undefined && value !== null) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, item.toString())
      })
    } else {
      formData.append(key, value.toString())
    }
  }
}

const GetInventoryObjectByCondition = (
  request: GetInventoryObjectByConditionRequest
): Promise<{data: InventoryObjectDTO[]}> => {
  const requestQuery = new URLSearchParams({
    inventoryObjectId: request.inventoryObjectId?.toString() || '',
    inventoryObjectName: request.inventoryObjectName || '',
    episodeId: request.episodeId?.toString() || '',
    page: request.page?.toString() || '',
    'per-page': request.perPage?.toString() || '',
    sort: request.sort || '',
    order: request.order || '',
    search: request.search || '',
  }).toString()

  return axios
    .get<{data: InventoryObjectDTO[]}>(
      `${API_INVENTORY_OBJECTS_URL}GetInventoryObjectByCondition?${requestQuery}`
    )
    .then((response: AxiosResponse<{data: InventoryObjectDTO[]}>) => response.data)
}

const CreateOrUpdateInventoryObject = async (
  inventoryObject: InventoryObjectDTO
): Promise<AxiosResponse<InventoryObjectDTO>> => {
  const url = `${API_INVENTORY_OBJECTS_URL}CreateOrUpdateInventoryObject`
  try {
    const _request: CreateOrUpdateInventoryObjectRequest = inventoryObject

    const response = await axios.post<InventoryObjectDTO>(url, _request, {
      headers: {'Content-Type': 'application/json'},
    })
    return response
  } catch (error) {
    const err = error as AxiosError
    if (err.response) {
      console.error('Server response error:', err.response.data)
    } else {
      console.error('Error saving inventory object:', err.message)
    }
    throw error
  }
}

const DeleteInventoryObjectRequest = (inventoryObjectId: ID): Promise<void> => {
  return axios.post(`${API_INVENTORY_OBJECTS_URL}Delete`, {
    inventoryObjectId,
  })
}

const UpdateInventoryObjectImage = async ({inventoryObjectId, form}: any) => {
  let result
  try {
    const response = await axios.post(
      `${API_INVENTORY_OBJECTS_URL}UpdateInventoryObjectImage?inventoryObjectId=${inventoryObjectId}`,
      form,
      {
        headers: {'Content-Type': 'multipart/form-data'},
      }
    )
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export {
  GetInventoryObjectByCondition,
  CreateOrUpdateInventoryObject,
  DeleteInventoryObjectRequest,
  UpdateInventoryObjectImage,
}
