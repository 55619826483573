import { FC, useEffect } from 'react';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components';
import { KTSVG, QUERIES } from '../../../../../_metronic/helpers';
import { useListView } from '../../core/ListViewProvider';
import Swal from 'sweetalert2';
import { useMutation, useQueryClient } from 'react-query';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import { EpisodeDTO, InventoryObjectDTO } from '../../../../models/dbModels';
import { DeleteInventoryObjectRequest } from '../../core/_request';
import { useNavigate } from 'react-router-dom';

type Props = {
  data: InventoryObjectDTO;
};

const ActionsCell: FC<Props> = ({ data }) => {
  const { setItemForUpdate } = useListView();
  const { query, refetch } = useQueryResponse();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditPage = () => {
    setItemForUpdate?.(data);
  };

  const deleteItem = useMutation(async () => await DeleteInventoryObjectRequest(data.inventoryObjectId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EPISODES}-${query}`]);
      refetch();
    },
  });

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a tárgyat?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    });

    if (result.isConfirmed) {
      await deleteItem.mutateAsync();
    }
  };

  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openEditPage}>
            Szerkesztés
          </a>
        </div>
        <div className='menu-item'>
          <a
            className='menu-link color-esl-red px-3'
            onClick={handleDeleteClick}
          >
            Töröl
          </a>
        </div>
      </div>
    </>
  );
};

export { ActionsCell };
