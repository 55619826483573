import { FC } from 'react';
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ListViewProvider } from "./core/ListViewProvider";
import { EpisodeList } from './table/EpisodeList';
import { EpisodeDTO } from '../../models/dbModels';

type Props = {
  episodes: EpisodeDTO[];
  seriesId: number;
};

const EpisodePageWrapper: FC<Props> = ({ episodes, seriesId }) => (
  <QueryRequestProvider>
    <QueryResponseProvider episodes={episodes} seriesId={seriesId}>
      <ListViewProvider>
        <EpisodeList seriesId={seriesId} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { EpisodePageWrapper };
