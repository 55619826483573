import {apiURL} from '@/app/consts'
import axios from 'axios'
import {useMutation} from 'react-query'

const baseURL = apiURL + 'Event'

interface DeleteArByIdParams {
  arGameId: number
}

export const deleteArById = async (data: DeleteArByIdParams) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/DeleteArGame`, data)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

interface CreateOrUpdateArGameParams {
  arGameId?: number
  eventId: number
  modelName: string
  numberOfItems: number
  placementRadius: number
}

export const createOrUpdateArGame = async (params: CreateOrUpdateArGameParams) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/CreateOrUpdateArGame`, params)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export const updateArGameFile = async ({arGameId, form}: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/UpdateArGameFile?arGameId=${arGameId}`, form, {
      headers: {'Content-Type': 'multipart/form-data'},
    })
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}
