import {FC} from 'react'
import {Column} from 'react-table'
import {InventoryObjectDTO} from '../../../models/dbModels'
import {Table} from '../../../common/table/Table'
import {KTCard} from '../../../../_metronic/helpers'
import {CustomHeader} from '../../../common/table/columns/CustomHeader'
import {ListHeader} from '../../../common/table/components/header/ListHeader'
import {InventoryObjectListToolbar} from '../components/header/InventoryObjectListToolbar'
import {useListView} from '../core/ListViewProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider'
import {ActionsCell} from './columns/ActionsCell'
import {InventoryObjectEditOrCreateModal} from '../edit-or-create-modal/InventoryObjectEditOrCreateModal'

type Props = {
  episodeId: number
}

const InventoryObjectList: FC<Props> = ({episodeId}) => {
  const {itemForUpdate} = useListView()
  const {updateState, state} = useQueryRequest()
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const inventoryObjects = useQueryResponseData() // Fetch inventory objects data

  const columns: ReadonlyArray<Column<InventoryObjectDTO>> = [
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='NÉV'
          className='min-w-125px'
        />
      ),
      accessor: 'inventoryObjectName',
      Cell: ({...props}) => props.data[props.row.index].inventoryObjectName,
    },
    {
      Header: (props) => (
        <CustomHeader
          state={state}
          updateState={updateState}
          tableProps={props}
          title='TEVÉKENYSÉG'
          className='text-end min-w-100px'
        />
      ),
      id: 'actions',
      disableSortBy: true,
      Cell: ({...props}) => <ActionsCell data={props.data[props.row.index]} />,
    },
  ]

  return (
    <>
      <h1>Reward-ok</h1>
      <KTCard>
        <ListHeader
          showSearch={true}
          updateState={updateState}
          toolbarComponent={<InventoryObjectListToolbar />}
        />
        <Table
          pagination={pagination}
          isLoading={isLoading}
          updateState={updateState}
          loadedData={inventoryObjects}
          columns={columns}
        />
      </KTCard>

      {itemForUpdate !== undefined && <InventoryObjectEditOrCreateModal episodeId={episodeId} />}
    </>
  )
}

export {InventoryObjectList}
