import {useMemo, FC} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {usersColumns} from './columns/_columns'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {UserDTO} from '../../../../models/dbModels'

type UsersTableProps = {
  selectedUsers: number[]
  onUserSelect: (userId: number) => void
  hiddenColumns?: string[]
  loading?: boolean
}

const UsersTable: FC<UsersTableProps> = ({
  selectedUsers = [],
  onUserSelect,
  hiddenColumns,
  loading,
}) => {
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => {
    const columns = usersColumns(selectedUsers, onUserSelect).filter(({id, accessor}) => {
      const prop = id || (accessor as string)
      return !hiddenColumns?.includes(prop)
    })
    return columns
  }, [selectedUsers, onUserSelect, hiddenColumns])
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: ColumnInstance<UserDTO>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            ))}
          </thead>

          <tbody className='text-gray-700' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<UserDTO>, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps({key: `row-${i}`})}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps({key: cell.column.id})}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nincs megjeleníthető találat
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {(loading || isLoading) && <UsersListLoading />}
    </KTCardBody>
  )
}

export {UsersTable}
