import { EpisodeDTO, EpisodeStateEnum, UserCompletedEpisodeDTO } from "./dbModels";
import { Response } from "@/_metronic/helpers";


//#region  CreateOrUpdateEpisode
export type CreateOrUpdateEpisodeRequest = {
    series: EpisodeDTO;
};

export type CreateOrUpdateEpisodeResponse = Response<EpisodeDTO>;
export type GetEpisodeByConditionResponse = Response<Array<EpisodeDTO>>;

//#endregion

//#region  UserCompletedEpisode
export type GetUserCompletedEpisodeResponse = Response<Array<UserCompletedEpisodeDTO>>;
//#endregion

export const initialEpisodeData: EpisodeDTO = {
    episodeId: 0,
    episodeName: '',
    state: EpisodeStateEnum.NotStarted,
    seriesId: 0,
    eventId: 0,
    isQr: false,
    episodeRewards: [],
    preconditions: [],
}