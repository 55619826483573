import { FC, useState, createContext, useContext } from 'react';
import { GeneralListViewContextProps, WithChildren, initialGeneralOrderListView } from '../../../../_metronic/helpers';
import { EpisodeDTO } from '../../../models/dbModels';

const ListViewContext = createContext<GeneralListViewContextProps<EpisodeDTO>>(initialGeneralOrderListView as GeneralListViewContextProps<EpisodeDTO>);

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [itemForUpdate, setItemForUpdate] = useState<EpisodeDTO>();
  const [itemForView, setItemForView] = useState<EpisodeDTO>();
  const [orderModal, setOrderModal] = useState<boolean>(false);

  return (
    <ListViewContext.Provider
      value={{
        orderModal,
        setOrderModal,
        itemForUpdate,
        setItemForUpdate,
        itemForView,
        setItemForView,
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };
