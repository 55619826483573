import {InventoryObjectDTO, InventoryObjectTypeEnum} from './dbModels'

export const initialInventoryObjectData: InventoryObjectDTO = {
  inventoryObjectId: 0,
  episodeId: 0,
  inventoryObjectName: '',
  description: '',
  imageSource: '',
  inventoryObjectType: InventoryObjectTypeEnum.Item,
  userCompletedEpisodeXInventoryObject: [],
}
