import axios from 'axios'

const API_URL = process.env.REACT_APP_ALTERGO_API

export const REGISTRATION_URL_PLAYER = `${API_URL}user/registration`
export const LOGIN_URL_PLAYER = `${API_URL}Authentication/Login`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}Authentication/Login`
export const REGISTER_URL = `${API_URL}Authentication/Register`
export const REQUEST_PASSWORD_URL = `${API_URL}Authentication/ForgotPassword`
export const SET_PASSWORD_URL = `${API_URL}Authentication/RenewPassword`
export const INVITE_REGISTER = `${API_URL}user/invite-registration`

// Server should return AuthModel
export function loginRequest(email: string, password: string) {
  let formData = new FormData()
  formData.append('EmailAddress', email)
  formData.append('Password', password)

  return axios({
    method: 'post',
    url: LOGIN_URL,
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

// Server should return AuthModel
export function registerRequest(
  emailAddress: string,
  firstName: string,
  lastName: string,
  password: string,
  phoneNumber: string
) {
  const formData: any = new FormData()
  formData.append('EmailAddress', emailAddress)
  formData.append('FirstName', firstName)
  formData.append('LastName', lastName)
  formData.append('PhoneNumber', phoneNumber)
  formData.append('Password', password)

  return axios.post(REGISTER_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function inviteRegister(
  token: string,
  email: string,
  password: string,
  last_name: string,
  first_name: string,
  phone: string,
  birth_date: string
) {
  return axios.post(INVITE_REGISTER, {
    invite_key: token,
    email: email,
    password: password,
    last_name: last_name,
    first_name: first_name,
    phone: phone,
    birth_date: birth_date,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(emailAddress: string) {
  const form = new FormData()
  form.append('EmailAddress', emailAddress)
  return axios({
    url: REQUEST_PASSWORD_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data: form,
  }) as any
}

export function setPassword(token: string, password: string) {
  debugger

  const form = new FormData()
  form.append('TemporaryToken', token)
  form.append('NewHashedPassword', password)

  return axios({
    url: SET_PASSWORD_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data: form,
  }) as any
}
