import {KTSVG} from '../../../../../_metronic/helpers'
import {initialInventoryObjectData} from '../../../../models/inventoryObject'
import {useListView} from '../../core/ListViewProvider'

const InventoryObjectListToolbar = () => {
  const {setItemForUpdate} = useListView()
  const openAddItemModal = () => {
    if (setItemForUpdate !== undefined) setItemForUpdate(initialInventoryObjectData)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddItemModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Új reward
      </button>
    </div>
  )
}

export {InventoryObjectListToolbar}
