import { FC, useContext, useState, useEffect, useMemo, createContext } from 'react';
import { useQuery } from 'react-query';
import { initialQueryState, PaginationState, QUERIES, QueryResponseContextProps, stringifyRequestQuery, WithChildren } from '../../../../_metronic/helpers';
import { useQueryRequest } from './QueryRequestProvider';
import { InventoryObjectDTO, GetInventoryObjectByConditionRequest } from '../../../models/dbModels';
import { GetInventoryObjectByCondition } from './_request';



const parseInventoryObjectListQueryString = (queryString: string): GetInventoryObjectByConditionRequest => {
    const params = new URLSearchParams(queryString);
    return {
      page: params.has('page') ? Number(params.get('page')) : undefined,
      perPage: params.has('per-page') ? Number(params.get('per-page')) : undefined,
      search: params.get('search') || undefined,
      order: params.get('order') || undefined,
    };
};
  

const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '', foldersListRefetch: () => { }, foldersListResponse: [], SeriesListRefetch: () => { }, SeriesListResponse: [] };

const QueryResponseContext = createContext<QueryResponseContextProps<InventoryObjectDTO>>(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren & {episodeId: number | undefined }> = ({ children, episodeId }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const { isFetching, refetch, data: response } = useQuery(
    `${QUERIES.INVENTORY_OBJECTS}-${query}`,
    async () => {
        const queryList = parseInventoryObjectListQueryString(query);
        const request: GetInventoryObjectByConditionRequest = { 
        episodeId: episodeId,
        ...queryList,
    };

      const fetchedInventoryObjects = await GetInventoryObjectByCondition(request);
      return fetchedInventoryObjects;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  console.log('Context response:', response);
  if (!response || !response.data) {
    return [];
  }
  return response.data;
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};