import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import {useParams} from 'react-router-dom'
import {InventoryObjectDTO, InventoryObjectTypeEnum} from '../../../../models/dbModels'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {Field} from '@/app/components/CustomInput'
import {CreateOrUpdateInventoryObject, UpdateInventoryObjectImage} from '../../core/_request'
import {ErrorData, errorHandler} from '@/_metronic/helpers'
import {AxiosError} from 'axios'

type Props = {
  isLoading: boolean
  data?: InventoryObjectDTO
}

const schema = Yup.object().shape({
  inventoryObjectName: Yup.string().min(3, 'Minimum 3 karakter.').required('Kötelező mező'),
  inventoryObjectType: Yup.number().required(),
  description: Yup.string()
    .min(3, 'Minimum 3 karakter.')
    .max(1000, 'Max. 1000 karakter')
    .required('Kötelező mező'),
  imageSource: Yup.string().required(),
})

const EditOrCreateInventoryObjectModalForm: FC<Props> = ({data, isLoading}) => {
  const {episodeId} = useParams<{episodeId: string}>()
  const numericEpisodeId = Number(episodeId)

  const [success, setSuccess] = useState<boolean>(false)

  const InventoryObjectForEdit: InventoryObjectDTO = {
    inventoryObjectId: data?.inventoryObjectId ?? 0,
    episodeId: data?.episodeId ?? numericEpisodeId,
    inventoryObjectName: data?.inventoryObjectName ?? '',
    description: data?.description ?? '',
    imageSource: data?.imageSource ?? '',
    inventoryObjectType: data?.inventoryObjectType || InventoryObjectTypeEnum.Item,
  }

  const {setItemForUpdate} = useListView()
  const {refetch, query} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
    // navigate('/inventoryObjects');
  }

  const onSubmit = async (values: any, {setStatus, setSubmitting, resetForm}: any) => {
    const requestData: any = {
      ...InventoryObjectForEdit,
      ...values,
      episodeId: numericEpisodeId,
    }
    setSubmitting(true)
    try {
      const {data: res}: any = await CreateOrUpdateInventoryObject(requestData)
      if (res.message || !res.inventoryObjectId) {
        throw new Error('Hiba a mentés során')
      }
      const formData = new FormData()
      const input: HTMLInputElement = document.getElementById(
        'ioImageSourceFile'
      ) as HTMLInputElement
      if (input && input.files && input.files[0]) {
        formData.append('imageFile', input.files[0])
        const updateRes: any = await UpdateInventoryObjectImage({
          inventoryObjectId: res.inventoryObjectId,
          form: formData,
        })
        if (updateRes.message) {
          throw new Error('Hiba a mentés során')
        }
      }
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
        cancel(true)
        resetForm()
      }, 500)
    } catch (error) {
      const err = error as AxiosError<ErrorData>
      setStatus('Failed to save, please try again!')
      if (err.response) {
        const errorText = errorHandler(
          err.response.status,
          err.response.data.errorResponse?.value?.errorInfo || '',
          err.response.data.errorResponse?.value?.errorDetails || ''
        )
        setStatus(errorText)
        setTimeout(() => {
          setStatus('')
        }, 2500)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const [initData, setInitData] = useState<any>()

  useEffect(() => {
    setInitData({
      inventoryObjectName: InventoryObjectForEdit.inventoryObjectName,
      inventoryObjectType: InventoryObjectForEdit.inventoryObjectType,
      description: InventoryObjectForEdit.description,
      imageSource: InventoryObjectForEdit.imageSource,
    })
  }, [data])

  return (
    <>
      <Formik
        initialValues={initData}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Field name='inventoryObjectName' label={'Név'} required />
          <Field name='description' label={'Leírás'} required textarea />
          <Field name='imageSource' label={'Kép'} required isFileUpload='ioImageSourceFile' />
          <Field name='inventoryObjectType' label={'Reward kategória'} required select>
            <option value={InventoryObjectTypeEnum['Item']}>Item</option>
            <option value={InventoryObjectTypeEnum['Character']}>Character</option>
            <option value={InventoryObjectTypeEnum['Loot']}>Loot</option>
            <option value={InventoryObjectTypeEnum['Clue']}>Clue</option>
          </Field>

          <div className='d-flex justify-content-center align-items-center mt-10'>
            <button type='submit' className='btn btn-primary'>
              MÓDOSÍTÁS
            </button>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export {EditOrCreateInventoryObjectModalForm}
