import axios, { AxiosError, AxiosResponse } from 'axios'
import { ID } from '../../../../../_metronic/helpers'
import { UserQueryResponse, UsersQueryResponse } from '@/app/models/user'
import { UserDTO } from '../../../../models/dbModels'


const URL_ALTERGO_ADMIN = process.env.REACT_APP_ALTERGO_API + "Administration/"

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${URL_ALTERGO_ADMIN + "GetUsers"}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = async (id: number): Promise<UserDTO | undefined> => {
  if (id <= 0) {
    console.error("Invalid UserId:", id);
    return undefined;
  }

  let formData = new FormData();
  formData.append('UserId', id.toString());

  const response: AxiosResponse<UserQueryResponse> = await axios({
    method: 'post',
    url: `${URL_ALTERGO_ADMIN}GetUser`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data;
}

const deleteUser = async (userId: number | undefined): Promise<void> => {
  if(!userId) return;
  
  let formData = new FormData();
  formData.append('UserId', userId?.toString());
  const response: AxiosResponse<UserQueryResponse> = await axios({
    method: 'post',
    url: `${URL_ALTERGO_ADMIN}DeleteUser`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function registerRequest(user: UserDTO) {
  return axios.post(URL_ALTERGO_ADMIN, user)
}

export { getUsers, deleteUser, getUserById }

