/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { userRoleList, UserRoleEnum } from '@/app/models/user'
import { UserDTO } from '../../../../../models/dbModels'

type Props = {
  user: UserDTO
}

const UserAccessCell: FC<Props> = ({user}) => {
  const role = userRoleList.find((role) => role.id === user.roleId)

  let badgeClass = 'badge rounded-pill';
  switch (user.roleId) {
    case UserRoleEnum.ADMIN:
      badgeClass += ' badge-light-danger';
      break;
    case UserRoleEnum.USER:
      badgeClass += ' badge-light-info';
      break;
  }
    
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className={badgeClass}>
          {role?.name || 'N/A'}
        </span>
      </div>
    </div>
  )
}

export {UserAccessCell}
