import { KTCard } from "../../../_metronic/helpers";
import { ListHeader } from "../../common/table/components/header/ListHeader";
import { Table } from '@/app/common/table/Table'
import { QueryRequestProvider, useQueryRequest } from "./core/QueryRequestProvider";
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from "./core/QueryResponseProvider";
import { Column } from "react-table";
import { CustomHeader } from "../../common/table/columns/CustomHeader";
import { ActionsCell } from "./table/columns/ActionsCell";
import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { ColorHexCell } from "./table/columns/ColorHexCell";
import { EditOrCreateSeriesModal } from "./edit-or-create-modal/EditOrCreateSeriesModal";
import { SeriesDTO } from "../../models/dbModels";
import { SeriesListToolbar } from "./components/header/SeriesListToolbar";


const SeriesList = () => {
  const { itemForUpdate, itemForView, setItemForView } = useListView()
  const { updateState, state } = useQueryRequest()
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const loadedData = useQueryResponseData()
  
  const columns: ReadonlyArray<Column<SeriesDTO>> = [
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Név' className='min-w-125px' />,
      accessor: 'seriesName',
      Cell: ({ ...props }) => props.data[props.row.index].seriesName,
    },
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='ID' className='min-w-125px' />,
      accessor: 'seriesId',
      Cell: ({ ...props }) => props.data[props.row.index].seriesId,
    },
    {
      Header: (props) => <CustomHeader state={state} updateState={updateState} tableProps={props} title='Szín' className='min-w-125px' />,
      accessor: 'colorHex',
      Cell: ({ ...props }) => <ColorHexCell series={props.data[props.row.index]} />,
    },
    {
      Header: (props) => (
        <CustomHeader state={state} updateState={updateState} tableProps={props} title='TEVÉKENYSÉG' className='text-end min-w-100px' />
      ),
      id: 'actions',
      disableSortBy: true,
      Cell: ({ ...props }) => <ActionsCell data={props.data[props.row.index]} />,
    },
  ];

  return (
    <>
      <h1 className="h1 page-heading">Sorozatok</h1>
      <KTCard>
        <ListHeader showSearch={true} updateState={updateState} toolbarComponent={<SeriesListToolbar />} />
        <Table pagination={pagination} isLoading={isLoading} updateState={updateState} loadedData={loadedData} columns={columns} />
      </KTCard>

      {/* // TODO: add modals */}
      {itemForUpdate !== undefined && <EditOrCreateSeriesModal />}
      {itemForView !== undefined && <EditOrCreateSeriesModal />}
    </>
  );
};

const SeriesPageWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
    <ListViewProvider>
      <SeriesList />
    </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { SeriesPageWrapper };
