/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { UserEditModalFormWrapper } from './user-modal/user-edit-modal/UserEditModalFormWrapper'
import { useDCTables } from '@/app/providers/DCTablesProvider'
import { UserDTO } from '../../../../app/models/dbModels'

type Props = {
  isLoading: boolean
  user: UserDTO | undefined
  error: unknown
  refetchUser: () => void
}

const HeaderUserMenu: FC<Props>  = ({user, isLoading, error, refetchUser}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { getDcList } = useDCTables();

  useEffect(() => {
    getDcList(['dC_Country']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    // setIsModalOpen(true);
    setIsModalOpen(!isModalOpen)
  };

  const handleOk = () => {
    // setIsModalOpen(false);
    setIsModalOpen(!isModalOpen)
  };

  const handleCancel = () => {
    refetchUser();
    setIsModalOpen(false);
  }
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-0 fs-6 w-150px'
      data-kt-menu='true'
    >
      <div className='menu-item px-1 my-1'>
        <button onClick={showModal} className='btn menu-link menu-item-hover px-5 w-100 text-dark'>
          Beállítások
        </button>

        <Modal className={"antd-modal-lg-w-1000"} title="Felhasználó" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
          footer={null}
        >
          {
            // <OrdersListWrapper handleOk={handleOk} />
            <UserEditModalFormWrapper user={user as UserDTO} isLoading={isLoading} error={error} cancel={handleCancel}/>
          }

        </Modal>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
