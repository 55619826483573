import { UserAuthModel } from "../../../models/user"

const TRAINEE_AUTH_LOCAL_STORAGE_KEY = 'trainee-auth-react-v'
const ESL_LOCAL_STORAGE_KEY = 'esl-auth-react-v'

const getAuth = (): UserAuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(ESL_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: UserAuthModel = JSON.parse(lsValue) as UserAuthModel
    if (auth) {
      // You can easily check auth_token expiration also


      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: UserAuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(ESL_LOCAL_STORAGE_KEY, lsValue)

  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}


const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(ESL_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getTraineeAuth = (): any | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(TRAINEE_AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: any = JSON.parse(lsValue) as any
    if (auth) {
      // You can easily check auth_token expiration also

      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setTraineeAuth = (auth: any) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(TRAINEE_AUTH_LOCAL_STORAGE_KEY, lsValue)

  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeTraineeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(TRAINEE_AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


export function initAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json'; // TODO 

  axios.interceptors.request.use(
    (config: any) => {
      const auth = getAuth()
      const traineeAuth = getTraineeAuth()

      const token = auth?._temporaryPasswordTokens || traineeAuth?.accessToken
      if(token)
        config.headers.Authorization = `Bearer ${token}`;

      // If the request is POST, add executingUserId to the request body
      if (config.method === 'post' && auth?.userId) {
        config.data.executingUserId = auth?.userId;
      }
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

}



export { getAuth, setAuth, removeAuth, TRAINEE_AUTH_LOCAL_STORAGE_KEY, ESL_LOCAL_STORAGE_KEY, getTraineeAuth, setTraineeAuth, removeTraineeAuth}
