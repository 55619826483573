import {KTSVG} from '@/_metronic/helpers'
import clsx from 'clsx'

export const DropdownMenu = ({children}: {children: any}) => {
  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >
        {Object.values(children).map((item: any, index: number) => {
          return (
            <div key={index} className='menu-item'>
              <span className={clsx('menu-link px-3', item.className)} onClick={item.onClick}>
                {item.name}
              </span>
            </div>
          )
        })}
      </div>
    </>
  )
}
