/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { SeriesDTO } from '../../../../models/dbModels';

type Props = {
  series: SeriesDTO;
};

const sanitizeHexCode = (hexCode: string): string => {
  return hexCode.replace('#', '');
};

const createDynamicStyle = (colorHex: string) => {
  const sanitizedHex = sanitizeHexCode(colorHex);
  const className = `cell-dynamic-hexa-colors-${sanitizedHex}`;

  // Check if the style already exists
  if (!document.querySelector(`.${className}`)) {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      .${className} {
        background-color: ${colorHex};
        color: #fff; /* Adjust text color based on your requirements */
      }
    `;
    document.head.appendChild(style);
  }

  return className;
};

const getBadgeClass = (colorHex: string): string => {
  return colorHex ? createDynamicStyle(colorHex) : 'badge-light-primary';
};

const ColorHexCell: FC<Props> = ({ series }) => {
  const badgeClass = getBadgeClass(series.colorHex || '');

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className={`badge ${badgeClass}`}>
          {series.colorHex}
        </span>
      </div>
    </div>
  );
};

export { ColorHexCell };
