import {KTSVG} from '@/_metronic/helpers'
import {ReactNode} from 'react'

export const Modal = ({
  open,
  setModal,
  children,
  title,
}: {
  open: boolean
  setModal: any
  children: ReactNode
  title?: string
}) => {
  return open ? (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_preconditions'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
        onClick={(e) => {
          if ((e.target as any).classList.contains('modal')) {
            setModal('')
          }
        }}
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{title || 'Szerkesztés'}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setModal('')}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-7'>{children}</div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  ) : null
}
