import axios, { AxiosError, AxiosResponse } from 'axios'
import { ID} from '../../../../../helpers'
import { sha256 } from 'crypto-hash'
import { UserDTO } from '../../../../../../app/models/dbModels'

const API_URL = process.env.REACT_APP_ALTERGO_API
export const URL_ALTERGO_ADMIN = `${API_URL}Administration/`

const getUserById = async (id: number): Promise<UserDTO | undefined> => {
  if (id <= 0) {
    console.error("Invalid UserId:", id);
    return undefined;
  }

  let formData = new FormData();
  formData.append('UserId', id.toString());

  try {
    const response: AxiosResponse<any> = await axios({
      method: 'post',
      url: `${URL_ALTERGO_ADMIN}GetUser`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Log the entire response for debugging purposes
    console.log('getUserById response:', response);

    if (response.data && response.data.isSuccessful) {
      return response.data.data;
    } else {
      console.error('Error fetching user:', response.data?.errorInfo, response.data?.errorDetails);
      return undefined;
    }
  } catch (error: AxiosError | any) {
    console.error('Error fetching user:', error.message || error);
    return undefined;
  }
};

const createOrUpdateUser = async (user: UserDTO, user_id?: ID): Promise<AxiosResponse<UserDTO>> => {
  const url = user_id ? `${API_URL}Administration/UpdateUser` : `${API_URL}Administration/CreateUser`;

  const formData = new FormData();

  const appendField = (key: string, value: any) => {
    if (value !== undefined && value !== null) {
      formData.append(key, value.toString());
    }
  };

  appendField('UserId', user.userId ?? '0');
  appendField('UserName', user.userName);
  appendField('FirstName', user.firstName);
  appendField('LastName', user.lastName);
  appendField('GenderId', user.genderId);
  appendField('EmailAddress', user.emailAddress);
  appendField('PhoneNumber', user.phoneNumber || ''); // Ensure phone number is not null
  appendField('PostalCode', user.postalCode);

  // Check if dateOfBirth is a Date object, if not convert it
  const dateOfBirth = typeof user.dateOfBirth === 'string' ? new Date(user.dateOfBirth) : user.dateOfBirth;
  appendField('DateOfBirth', dateOfBirth.toISOString());

  appendField('AszfAccepted', user.aszfAccepted ? 1 : 0);
  appendField('MarketingStatementAccepted', user.marketingStatementAccepted ? 1 : 0);
  appendField('IsAdult', user.isAdult ? 1 : 0);
  appendField('RoleId', user.roleId);
  appendField('IsDeleted', user.isDeleted ? 1 : 0);
  appendField('Password', user.password);
  
  return axios({
    method: 'post',
    url: url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((response: AxiosResponse<UserDTO>) => {
    return response;
  }).catch((error: AxiosError) => {
    if (error.response) {
      console.error('Server response error:', error.response.data);
    } else {
      console.error('Error saving user:', error.message);
    }
    throw error;
  });
};

export { getUserById, createOrUpdateUser }

