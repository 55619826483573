import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorData, errorHandler} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {CreateOrUpdateSeries} from '../../core/_requests'
import {SeriesDTO} from '../../../../models/dbModels'

type Props = {
  isLoading: boolean
  data: SeriesDTO | undefined
}

const CreateSeriesModalForm: FC<Props> = ({data, isLoading}) => {
  const [success, setSuccess] = useState<boolean>(false)

  const DataForCreate = data ?? {
    seriesId: 0,
    seriesName: '',
    colorHex: '',
    episodes: [],
    assignToAllUsers: false,
  }

  useEffect(() => {
    if (data) {
      formik.setValues(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const {setItemForUpdate} = useListView()
  const {refetch, query} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
  }

  const formik = useFormik({
    initialValues: DataForCreate,
    validationSchema: Yup.object().shape({
      seriesName: Yup.string().min(3, 'Minimum 3 karakter.').required('Kötelező mező'),
      colorHex: Yup.string()
        .matches(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, 'Érvényes HEX színt adjon meg.')
        .required('Kötelező mező'),
      assignToAllUsers: Yup.boolean().nullable(),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = Object.assign(DataForCreate, formik.values)

        const {data} = await CreateOrUpdateSeries(formData as SeriesDTO)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(
            err.response.status,
            err.response.data.errorResponse?.value?.errorInfo || '',
            err.response.data.errorResponse?.value?.errorDetails || ''
          )
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_series_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='fv-row mb-8'>
            <div className='row g-4'>
              <div className='col-12'>
                <label className='fs-6 mb-2 required'>Sorozat Neve</label>
                <input
                  placeholder='Sorozat neve'
                  {...formik.getFieldProps('seriesName')}
                  type='text'
                  name='seriesName'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.seriesName && formik.errors.seriesName},
                    {
                      'is-valid': formik.touched.seriesName && !formik.errors.seriesName,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.seriesName && formik.errors.seriesName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.seriesName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <div className='d-flex flex-row justify-content-between'>
                  <label className='fs-6 mb-2 required'>Szín </label>
                  <a href='https://wpaulina.hu/asztali/wwwww/htmlszin.html' target='_blank'>
                    Színkód segédlet
                  </a>
                </div>
                <div className='col-6'></div>
                <input
                  placeholder='HEX'
                  {...formik.getFieldProps('colorHex')}
                  type='text'
                  name='colorHex'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.colorHex && formik.errors.colorHex},
                    {
                      'is-valid': formik.touched.colorHex && !formik.errors.colorHex,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.colorHex && formik.errors.colorHex && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.colorHex}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className=''>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    type='checkbox'
                    autoComplete='off'
                    checked={formik.values.assignToAllUsers}
                    className='form-check-input py-5 custom-switch'
                    onChange={() => {
                      formik.setFieldValue('assignToAllUsers', !formik.values.assignToAllUsers)
                    }}
                  />
                  <label className='form-check-label' htmlFor='flexSwitchDefault'>
                    Elérhető az összes felhasználó számára
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres mentés!</div>
          </div>
        )}

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Mégse
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {CreateSeriesModalForm}
