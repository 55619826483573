/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { selectStatusType } from '../../../../../../_metronic/helpers'
import { UserDTO } from '../../../../../models/dbModels'

type Props = {
  user: UserDTO
}

const UserStatusCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className={`badge ${(!user.isDeleted) && ' badge-light-success'} ${(!user.isDeleted) && ' badge-light-error' } `}>
          {selectStatusType(!user.isDeleted)}
      </span>
    </div>
  </div>
)

export { UserStatusCell }
