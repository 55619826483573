/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {loginRequest} from '../core/_requests'
import {ErrorData, errorHandler} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {AxiosError} from 'axios'
import EyeButton from './eye-button/EyeButton'
import {LoginUserResponseDataModel} from '../core/_models'
import {UserAuthModel} from '../../../models/user'
import {sha256} from 'crypto-hash'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Nem megfelelő e-mail formátum')
    .min(3, 'Minimum 3 karakter')
    .required('Kötelező mező'),
  password: Yup.string().min(3, 'Minimum 3 karakter').required('Kötelező mező'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const roles = {
  ADMIN: 1,
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {setCurrentUser, saveAuth} = useAuth()
  const [passwordType, setPasswordType] = useState('password')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: authResponse} = await loginRequest(values.email, await sha256(values.password))

        // dump non-admins
        const roleId = authResponse?.data?.roleId
        if (!roleId || roleId !== roles.ADMIN) {
          throw new Error('unauthorized')
        }

        const user: UserAuthModel = convertToUser(authResponse?.data)

        // TODO remove / chack
        saveAuth(user)
        setCurrentUser(user)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Valami hiba történt, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(
            err.response.status,
            err.response.data.errorResponse?.value?.errorInfo || '',
            err.response.data.errorResponse?.value?.errorDetails || ''
          )
          setStatus(errorText)
          saveAuth(undefined)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(true)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Bejelentkezés</h1>
      </div>
      {/* begin::Heading */}

      {/* {
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>info@sbcloud.hu</strong> and password <strong>12345678A</strong> to
            continue.
          </div>
        </div>
      } */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
        <input
          placeholder='E-mail'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3 ' data-kt-password-meter='true'>
        <div className='mb-1 position-relative'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Jelszó</label>
          <div className='position-relative'>
            <input
              type={passwordType}
              autoComplete='off'
              placeholder='Jelszó'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <EyeButton
              passwordType={passwordType}
              setPasswordType={setPasswordType}
              password={formik.values.password}
            ></EyeButton>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Elfelejtett jelszó
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Bejelentkezés</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Nincs még regisztrálva?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Regisztráció
        </Link>
      </div> */}
    </form>
  )
}

const convertToUser = (data: LoginUserResponseDataModel): UserAuthModel => {
  return {
    userId: data.userId,
    userName: data.userName,
    password: '', // password should not be saved in the auth response
    firstName: data.firstName,
    lastName: data.lastName,
    genderId: data.genderId,
    emailAddress: data.emailAddress,
    phoneNumber: data.phoneNumber,
    postalCode: data.postalCode,
    dateOfBirth: new Date(data.dateOfBirth),
    aszfAccepted: data.aszfAccepted,
    marketingStatementAccepted: data.marketingStatementAccepted,
    isAdult: data.isAdult,
    roleId: data.roleId,
    _temporaryPasswordTokens: data.token,
    isDeleted: data.isDeleted,
    creationDate: data.creationDate,
  }
}
