import {FC, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorData, GenderData, QUERIES, errorHandler} from '../../../../../../_metronic/helpers'
import {RightEnum, initialUser, userRoleList} from '@/app/models/user'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {UsersListLoading} from '../../components/loading/UsersListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {deleteUser} from '../../core/_requests'
import {sha256} from 'crypto-hash'
import {useMutation, useQueryClient} from 'react-query'
import FormikSelect from '@/app/modules/widgets/components/FormikSelect'
import {useDCTables} from '@/app/providers/DCTablesProvider'
import Swal from 'sweetalert2'
import {useAuth} from '@/app/modules/auth'
import {DatePicker, DatePickerProps} from 'antd'
import moment from 'moment/moment'
import {createOrUpdateUser} from '../../../../../../_metronic/partials/layout/header-menus/user-modal/core/_requests'
import {UserDTO} from '../../../../../models/dbModels'

type Props = {
  isUserLoading: boolean
  User: UserDTO | undefined
  altCancel?: () => void
}

const globalUserSchema = {
  emailAddress: Yup.string()
    .email('Rossz e-mail formátum')
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező'),
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező')
    .typeError('Kötelező mező'),
  firstName: Yup.string()
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 karakter.')
    .max(50, 'Maximum 50 karakter.')
    .required('Kötelező mező'),
  dateOfBirth: Yup.date(),
  aszfAccepted: Yup.boolean(),
  marketingStatementAccepted: Yup.boolean(),
  roleId: Yup.number().required('Kötelező mező'),
  genderId: Yup.string().required('Kötelező mező'),
  postalCode: Yup.string()
    .required('Kötelező mező')
    .max(4, 'Maximum 4 karakter.')
    .min(4, 'Minimum 4 karakter.'),
  userName: Yup.string().required('Kötelező mező'),
}

const editUserSchema = Yup.object().shape({
  ...globalUserSchema,
  password: Yup.string().min(3, 'Minimum 3 karakter.').nullable(),
})
const createUserSchema = Yup.object().shape({
  ...globalUserSchema,
  password: Yup.string().min(3, 'Minimum 3 karakter.').required('Kötelező mező'),
})

const UserEditModalForm: FC<Props> = ({User, isUserLoading, altCancel}) => {
  const [success, setSuccess] = useState<boolean>(false)

  const UserForEdit = User ? {...User} : {...initialUser}
  UserForEdit.password = ''

  useEffect(() => {
    if (User) {
      formik.setValues({...User, password: ''})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User])

  const isNewUser = !User?.userId

  const {setItemForUpdate} = useListView()
  const {refetch, query} = useQueryResponse()
  const queryClient = useQueryClient()

  const {dcTables} = useDCTables()

  const {currentUserCan, auth, logout} = useAuth()

  const countryList = useMemo(() => {
    return dcTables?.dC_Country ?? []
  }, [dcTables])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    altCancel?.()
    setItemForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: UserForEdit,
    validationSchema: !isNewUser ? editUserSchema : createUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const user = Object.assign(UserForEdit, formik.values)
        user.genderId = parseInt(user?.genderId as any)

        // password has been updated
        if (!isNewUser) {
          if (formik.values.password) {
            user.password = await sha256(formik.values.password)
          } else {
            user.password = ''
          }
        }

        if (isNewUser) {
          const sha256Pw = await sha256(user.password || '')
          if (!User?.userId) {
            user.password = sha256Pw
          }
        }

        const {data} = await createOrUpdateUser(user, user.userId)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(
            err.response.status,
            err.response.data.errorResponse?.value?.errorInfo || '',
            err.response.data.errorResponse?.value?.errorDetails || ''
          )
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a felhasználói fiókot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const deleteItem = useMutation(() => deleteUser(User?.userId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      cancel(true)

      if (User?.userId === auth?.userId) {
        logout()
      }
    },
  })

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    formik.setFieldValue('dateOfBirth', dateString)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='fv-row mb-8'>
            <div className='row g-4'>
              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>Vezetéknév</label>
                <input
                  placeholder='Vezetéknév'
                  {...formik.getFieldProps('lastName')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                    {'is-valid': formik.touched.lastName && !formik.errors.lastName}
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.lastName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>Keresztnév</label>
                <input
                  placeholder='Keresztnév'
                  {...formik.getFieldProps('firstName')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                    {'is-valid': formik.touched.firstName && !formik.errors.firstName}
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.firstName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>Felhasználónév</label>
                <input
                  placeholder='Felhasználónév'
                  {...formik.getFieldProps('userName')}
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.userName && formik.errors.userName},
                    {'is-valid': formik.touched.userName && !formik.errors.userName}
                  )}
                  autoComplete='one-time-code'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.userName && formik.errors.userName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.userName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>E-mail</label>
                <input
                  placeholder='E-mail'
                  {...formik.getFieldProps('emailAddress')}
                  type='email'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress},
                    {'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress}
                  )}
                  autoComplete='one-time-code'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailAddress}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className={clsx('fs-6 mb-2', {required: !User})}>
                  {User ? 'Új jelszó' : 'Jelszó'}
                </label>
                <input
                  placeholder='Min. 3 karakter'
                  {...formik.getFieldProps('password')}
                  type='password'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {'is-valid': formik.touched.password && !formik.errors.password}
                  )}
                  // https://robindirksen.com/blog/html-autocomplete-one-time-code
                  autoComplete='one-time-code'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className={'fv-row mb-8'}>
                <div className='row'>
                  <div className='col-lg-6'>
                    <label className='fw-bold fs-6 mb-2 required'>Születési dátum</label>
                    <DatePicker
                      format={'YYYY-MM-DD'}
                      defaultOpen={false}
                      value={moment(formik.values.dateOfBirth, 'YYYY-MM-DD')}
                      className={clsx([
                        {'is-invalid': formik.errors.dateOfBirth},
                        {'is-valid': !formik.errors.dateOfBirth},
                        'form-control form-control-solid mb-3 mb-lg-0',
                      ])}
                      onChange={onChange}
                    />
                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateOfBirth as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 d-flex align-items-center'>
                    <div className=''>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          placeholder='Korhatár megerősítve'
                          type='checkbox'
                          autoComplete='off'
                          checked={formik.values.isAdult}
                          className='form-check-input py-5 custom-switch'
                          onChange={() => {
                            formik.setFieldValue('isAdult', !formik.values.isAdult)
                          }}
                        />
                        <label className='form-check-label' htmlFor='flexSwitchDefault'>
                          Korhatár megerősítve
                        </label>
                      </div>
                    </div>

                    {formik.touched.isAdult && formik.errors.isAdult && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.isAdult}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='fv-row mb-8'>
                <div className='row'>
                  <div className='col-lg-6 mt-6 mt-lg-0'>
                    <label className='fw-bold fs-6 mb-2 required'>Telefonszám</label>
                    <input
                      placeholder='Telefon'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('phoneNumber')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                        },
                        {
                          'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                        }
                      )}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.phoneNumber}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 mt-6 mt-lg-0'>
                    <label className='fw-bold fs-6 mb-2 required'>Irányítószám</label>
                    <input
                      placeholder='Irányítószám'
                      type='text'
                      autoComplete='off'
                      maxLength={4}
                      {...formik.getFieldProps('postalCode')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.postalCode && formik.errors.postalCode},
                        {'is-valid': formik.touched.postalCode && !formik.errors.postalCode}
                      )}
                    />
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.postalCode}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className='col-lg-6 mt-6 mt-lg-0'>
                      <label className='fw-bold fs-6 mb-2 required'>Irányítószám</label>
                      <input
                          placeholder='Irányítószám'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('postalCode')}
                          className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              {
                                  'is-invalid': formik.touched.postalCode && formik.errors.postalCode,
                              },
                              {
                                  'is-valid': formik.touched.postalCode && !formik.errors.postalCode,
                              }
                          )}
                      />
                      {formik.touched.postalCode && formik.errors.postalCode && (
                          <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.postalCode}</span>
                              </div>
                          </div>
                      )}
                  </div> */}
                </div>
              </div>

              <div className='fv-row mb-8'>
                <div className='row'>
                  <div className='col-lg-6 mt-6 mt-lg-0'>
                    <FormikSelect
                      labelText='Neme'
                      selectProps={{
                        ...formik.getFieldProps('genderId'),
                        onBlur: formik.handleBlur,
                      }}
                      options={GenderData.map((item) => ({
                        id: item.id?.toString() ?? '',
                        name: item.name ?? '',
                      }))}
                      formikTouched={formik.touched.genderId}
                      formikErrors={formik.errors.genderId}
                      required={true}
                    />
                  </div>

                  {currentUserCan(RightEnum._admin) && (
                    <div className='col-lg-6 mt-6 mt-lg-0'>
                      <FormikSelect
                        labelText='Jogosultság'
                        selectProps={{
                          ...formik.getFieldProps('roleId'),
                          onBlur: formik.handleBlur,
                        }}
                        options={userRoleList.map((item) => ({
                          id: item.id.toString(),
                          name: item.name,
                        }))}
                        formikTouched={formik.touched.roleId}
                        formikErrors={formik.errors.roleId}
                        required={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='fv-row'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='mb-10'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    placeholder='Aszf elfogadva'
                    name={'aszfAccepted'}
                    type='checkbox'
                    autoComplete='off'
                    checked={formik.values.aszfAccepted}
                    className='form-check-input py-5'
                    onChange={() => {
                      formik.setFieldValue('aszfAccepted', !formik.values.aszfAccepted)
                    }}
                  />
                  <label className='form-check-label' htmlFor='flexSwitchDefault'>
                    Aszf elfogadva
                  </label>
                </div>
              </div>

              {/*</label>*/}
              {formik.touched.aszfAccepted && formik.errors.aszfAccepted && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.aszfAccepted}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-lg-6'>
              <div className='mb-10'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    placeholder='Aszf elfogadva'
                    name={'marketingStatementAccepted'}
                    type='checkbox'
                    autoComplete='off'
                    checked={formik.values.marketingStatementAccepted}
                    className='form-check-input py-5'
                    onChange={() => {
                      formik.setFieldValue(
                        'marketingStatementAccepted',
                        !formik.values.marketingStatementAccepted
                      )
                    }}
                  />
                  <label className='form-check-label' htmlFor='flexSwitchDefault'>
                    Marketing hozzájárulás elfogadva
                  </label>
                </div>
              </div>
              {formik.touched.marketingStatementAccepted &&
                formik.errors.marketingStatementAccepted && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.marketingStatementAccepted}</span>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres profil mentés!</div>
          </div>
        )}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          {!isNewUser && (
            <button
              type='button'
              onClick={handleDeleteClick}
              className='btn btn-danger me-3'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Törlés
            </button>
          )}

          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Mégse
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}
