import {apiURL} from '@/app/consts'
import axios from 'axios'
const baseURL = apiURL + 'Event'

export const createOrUpdateSurvey = async (params: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/CreateOrUpdateSurvey`, params)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}

export const deleteQuestionById = async (data: any) => {
  let result
  try {
    const response = await axios.post(`${baseURL}/DeleteSurvey`, data)
    result = response.data
  } catch (error: any) {
    result = {message: error.message}
  }
  return result
}
