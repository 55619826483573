import {Response} from '@/_metronic/helpers'
import {EpisodeDTO, SeriesDTO, SeriesStateEnum, UserXSeriesDTO} from './dbModels'

//#region Series ENUMS
//#endregion

//#region  CreateOrUpdateSeries
export type CreateOrUpdateSeriesRequest = {
  SeriesId?: number
  // Sorozat neve
  SeriesName: string
  // 2 állapot: elérhető vagy teljesített
  State: SeriesStateEnum
  // POI-k rádiuszának színe, hex kóddal
  ColorHex: string
  Episodes?: Array<EpisodeDTO>
  UserXSeries?: Array<UserXSeriesDTO> // Update this line
  AssignToAllUsers?: boolean
}

export type CreateOrUpdateSeriesResponse = Response<SeriesDTO>

export type GetSeriesByConditionResponse = Response<Array<SeriesDTO>>
//#endregion

export const initialSeriesData: SeriesDTO = {
  seriesId: 0,
  seriesName: '',
  colorHex: '',
  episodes: [],
  userXSeries: [],
  creationDate: new Date(),
  isDeleted: false,
}
