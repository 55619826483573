import {DATE_FORMATS, KTSVG} from '@/_metronic/helpers'
import {FC, useEffect, useMemo} from 'react'
import {useListView} from '../core/ListViewProvider'
import {DrawerComponent} from '@/_metronic/assets/ts/components'
import { useNavigate } from 'react-router-dom'
import { DCList, useDCTables } from '@/app/providers/DCTablesProvider'
import { userRoleList } from '@/app/models/user'
import { format } from "date-fns";

const UserViewDrawer: FC = () => {
  const {setItemForUpdate, itemForView, setItemForView} = useListView()
  const drawer = DrawerComponent.getInstance('userViewDrawer')

  const { dcTables } = useDCTables();

  const navigate = useNavigate();

  const handleEditClick = () => {
    close()
    setItemForUpdate(itemForView)
  }

  const close = () => {
    drawer?.hide()
  }

  useEffect(() => {
    if (drawer) {
      drawer.on('kt.drawer.after.hidden', function () {
        setItemForView(undefined)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      id='userViewDrawer'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='userViewDrawer'
      data-kt-drawer-activate='true'
      data-kt-drawer-width="{default:'300px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-permanent='true'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='userViewDrawerHeader'>
          <h3 className='card-title fw-bolder text-dark'>
            {itemForView?.firstName + ' ' + itemForView?.lastName}
          </h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              onClick={close}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
          </div>
        </div>
        <div className='card-body position-relative' id='userViewDrawerBody'>
          <div
            id='kt_activities_scroll'
            className='position-relative scroll-y me-n5 pe-5'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#userViewDrawerBody'
            data-kt-scroll-dependencies='#userViewDrawerHeader, #userViewDrawerFooter'
            data-kt-scroll-offset='5px'
          >
            <h3 className='h5 mb-5'>Felhasználói adatok</h3>

            <ul className='user-info-list'>
              <li className='user-info-list__item'>
                <div className='row gx-2 align-items-center'>
                  <div className='col-auto'>
                    <span className='user-info-list__icon'>
                    <i className="la la-envelope"></i>
                    </span>
                  </div>
                  <div className='col'>
                    <span className='user-info-list__text'>{itemForView?.emailAddress}</span>
                  </div>
                </div>
              </li>
              <li className='user-info-list__item'>
                <div className='row gx-2 align-items-center'>
                  <div className='col-auto'>
                    <span className='user-info-list__icon'>
                    <i className="la la-file"></i>
                    </span>
                  </div>
                  <div className='col'>
                    <span className='user-info-list__text'>Kártyák</span>
                  </div>
                  <div className="col-auto">
                    <button type='button' onClick={() => navigate('/cards?userId=' + itemForView?.userId)} className='btn btn-active-light-primary p-2'>
                      <i className="la la-angle-right"></i>
                    </button>
                  </div>
                </div>
              </li>
              <li className='user-info-list__item'>
                <div className='row gx-2 align-items-center'>
                  <div className='col-auto'>
                    <span className='user-info-list__icon'>
                    <i className="la la-folder-open"></i>
                    </span>
                  </div>
                  <div className='col'>
                    <span className='user-info-list__text'>Sorozatok</span>
                  </div>
                  <div className="col-auto">
                    <button type='button' onClick={() => navigate('/series?userId=' + itemForView?.userId)} className='btn btn-active-light-primary p-2'>
                      <i className="la la-angle-right"></i>
                    </button>
                  </div>
                </div>
              </li>
              <li className='user-info-list__item'>
                <div className='row gx-2 align-items-center'>
                  <div className='col-auto'>
                    <span className='user-info-list__icon'>
                    <i className="la la-user"></i>
                    </span>
                  </div>
                  <div className='col'>
                    <span className='user-info-list__text'>{ userRoleList.find(role => role.id === itemForView?.roleId)?.name }</span>
                  </div>
                </div>
              </li>
              <li className='user-info-list__item'>
                <div className='row gx-2 align-items-center'>
                  <div className='col-auto'>
                    <span className='user-info-list__icon'>
                    <i className="la la-calendar"></i>
                    </span>
                  </div>
                  <div className='col'>
                    <div className='user-info-list__text'>
                      <div>Regisztráció dátuma</div>
                      <div className='fs-6'>{itemForView?.creationDate ? format(itemForView?.creationDate, DATE_FORMATS.DATETIME) : ''}</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='card-footer py-5 text-center' id='userViewDrawerFooter'>
          <button type='button' onClick={handleEditClick} className='btn btn-primary'>
            Felasználó szerkesztése
          </button>
        </div>
      </div>
    </div>
  )
}

export {UserViewDrawer}
