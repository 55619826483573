import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'
import {ErrorData, errorHandler} from '../../../../../_metronic/helpers'
import {AssignUserToSeries, CreateOrUpdateSeries} from '../../core/_requests'
import {ListLoading} from '@/app/common/table/components/loading/ListLoading'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {UsersListFieldWrapper} from '../../../users/users-list/UsersListField'
import {SeriesDTO} from '../../../../models/dbModels'
import {EpisodePageWrapper} from '../../../episode'

type Props = {
  isLoading: boolean
  data: SeriesDTO | undefined
}

const EditSeriesModalForm: FC<Props> = ({data, isLoading}) => {
  const navigate = useNavigate()
  const [success, setSuccess] = useState<boolean>(false)
  const [editHeaderOpened, setEditHeaderOpened] = useState<boolean>(false)
  const selectedUserIds = (data?.userXSeries || []).map(({userId}) => userId) || []
  const [selectedUsers, setSelectedUsers] = useState<number[]>(selectedUserIds)
  const [assignLoading, setAssignLoading] = useState(false)

  const DataForEdit: SeriesDTO = {
    seriesId: data?.seriesId ?? 0,
    seriesName: data?.seriesName ?? '',
    colorHex: data?.colorHex ?? '',
    episodes: data?.episodes ?? [],
    userXSeries: data?.userXSeries ?? [],
    isDeleted: data?.isDeleted ?? false,
    creationDate: data?.creationDate ?? new Date(),
    assignToAllUsers: data?.assignToAllUsers ?? false,
  }

  useEffect(() => {
    if (data) {
      formik.setValues(DataForEdit)
      // setSelectedUsers(data.userXSeries?.map((userXSeries) => userXSeries.user) ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleEditClick = () => {
    setEditHeaderOpened(true)
  }

  const {setItemForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
    setEditHeaderOpened(false)
    navigate('/series')
  }

  const handleUserSelect = async (userId: number) => {
    setAssignLoading(true)
    const isUserSelectedBeforeSelect = selectedUsers.some(
      (selectedUserId) => selectedUserId && selectedUserId === userId
    )
    AssignUserToSeries({
      seriesId: formik.values.seriesId,
      userId: userId,
      isAssign: !isUserSelectedBeforeSelect,
    }).finally(() => {
      setAssignLoading(false)
    })
    setSelectedUsers((prevUsers = []) => {
      if (isUserSelectedBeforeSelect) {
        return prevUsers.filter((selectedUserId) => selectedUserId !== userId)
      } else {
        return [...prevUsers, userId]
      }
    })
  }

  const formik = useFormik({
    initialValues: DataForEdit,
    validationSchema: Yup.object().shape({
      seriesName: Yup.string().min(3, 'Minimum 3 karakter.').required('Kötelező mező'),
      colorHex: Yup.string()
        .matches(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, 'Érvényes HEX színt adjon meg.')
        .required('Kötelező mező'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData: SeriesDTO = {
          seriesId: values.seriesId,
          seriesName: values.seriesName,
          colorHex: values.colorHex,
          episodes: values.episodes,
          isDeleted: values.isDeleted,
          creationDate: values.creationDate,
          userXSeries: values.userXSeries,
          assignToAllUsers: values.assignToAllUsers,
        }

        await CreateOrUpdateSeries(formData)

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2500)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a mentés, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(
            err.response.status,
            err.response.data.errorResponse?.value?.errorInfo || '',
            err.response.data.errorResponse?.value?.errorDetails || ''
          )
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          <h1 className='h1 page-heading'>Sorozat</h1>
        </div>
        <div className='col-auto'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Vissza
          </button>

          {editHeaderOpened ? (
            <>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={isLoading || formik.isSubmitting || !formik.touched}
                onClick={() => formik.handleSubmit()}
              >
                <span className='indicator-label'>Mentés</span>
                {(formik.isSubmitting || isLoading) && (
                  <span className='indicator-progress'>
                    Kérem várjon...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </>
          ) : (
            <button className='btn btn-primary' disabled={isLoading} onClick={handleEditClick}>
              Sorozat szerkesztése
            </button>
          )}
        </div>
      </div>

      {editHeaderOpened && (
        <form
          id='kt_modal_edit_series_form'
          className='form card p-8'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='d-flex flex-column me-n7 pe-7'>
            <div className='fv-row mb-8'>
              <div className='row g-4'>
                <div className='col-6'>
                  <label className='fs-6 mb-2 required'>Sorozat Neve</label>
                  <input
                    placeholder='Sorozat neve'
                    {...formik.getFieldProps('seriesName')}
                    type='text'
                    name='seriesName'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                      'is-invalid': formik.touched.seriesName && formik.errors.seriesName,
                      'is-valid': formik.touched.seriesName && !formik.errors.seriesName,
                    })}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                  />
                  {formik.touched.seriesName && formik.errors.seriesName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.seriesName}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-6'>
                  <label className='fs-6 mb-2 required'>Szín</label>
                  <input
                    placeholder='HEX'
                    {...formik.getFieldProps('colorHex')}
                    type='text'
                    name='colorHex'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                      'is-invalid': formik.touched.colorHex && formik.errors.colorHex,
                      'is-valid': formik.touched.colorHex && !formik.errors.colorHex,
                    })}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                  />
                  {formik.touched.colorHex && formik.errors.colorHex && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.colorHex}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className=''>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      type='checkbox'
                      autoComplete='off'
                      checked={formik.values.assignToAllUsers}
                      className='form-check-input py-5 custom-switch'
                      onChange={() => {
                        formik.setFieldValue('assignToAllUsers', !formik.values.assignToAllUsers)
                      }}
                    />
                    <label className='form-check-label' htmlFor='flexSwitchDefault'>
                      Elérhető az összes felhasználó számára
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {formik.status && (
            <div className='alert alert-danger mt-6'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {success && (
            <div className='mb-10 bg-light-success p-4 rounded mt-6'>
              <div className='text-success'>Sikeres mentés!</div>
            </div>
          )}
        </form>
      )}

      {/* Episodes Section */}
      <EpisodePageWrapper
        episodes={formik.values.episodes ?? []}
        seriesId={formik.values.seriesId ?? 0}
      />

      {/* Users Section */}
      <UsersListFieldWrapper
        selectedUsers={selectedUsers}
        onUserSelect={handleUserSelect}
        loading={assignLoading}
      />
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {EditSeriesModalForm}
