import { KTSVG } from '../../../../../_metronic/helpers';
import { useListView } from '../../core/ListViewProvider';
import { initialEpisodeData } from '@/app/models/episode';
import { EpisodeListFilter } from './EpisodeListFilter';

const EpisodeListToolbar = () => {
  const { setItemForUpdate } = useListView();
  const openAddItemModal = () => {
    if (setItemForUpdate !== undefined)
      setItemForUpdate(initialEpisodeData);
  };

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <EpisodeListFilter />
      <button type='button' className='btn btn-primary' onClick={openAddItemModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Új epizód
      </button>
    </div>
  );
};

export { EpisodeListToolbar };
