import {FC, useContext, useState, useEffect, useMemo, createContext} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryState,
  PaginationState,
  QUERIES,
  QueryResponseContextProps,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {EpisodeDTO, GetEpisodeByConditionRequest} from '../../../models/dbModels'
import {GetEpisodeByCondition} from './_request'

const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  query: '',
  foldersListRefetch: () => {},
  foldersListResponse: [],
  SeriesListRefetch: () => {},
  SeriesListResponse: [],
}

const QueryResponseContext =
  createContext<QueryResponseContextProps<EpisodeDTO>>(initialQueryResponse)

const QueryResponseProvider: FC<
  WithChildren & {episodes: EpisodeDTO[] | undefined; seriesId: number | undefined}
> = ({children, episodes, seriesId}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.EPISODES}-${query}`,
    async () => {
      // THIS IS AGAINST REFETCH!!!
      // if (episodes) {
      //   return {data: episodes}
      // }

      const request: GetEpisodeByConditionRequest = {seriesId: seriesId}

      const fetchedEpisodes = await GetEpisodeByCondition(request)
      console.log('episodes', fetchedEpisodes)
      return fetchedEpisodes
    },
    {keepPreviousData: false, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  console.log('Context response:', response)
  if (!response || !response.data) {
    return []
  }
  return response.data
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
